<template>
    <section class="tw-group tw-h-full">
        <div class="tw-flex tw-space-x-6 tw-items-start tw-h-full">
            <div class="tw-w-36 tw-flex-shrink-0">
                <router-link :to="to">
                    <div v-if="post.featuredImage" class="tw-aspect-ratio-1:1 tw-rounded-sm tw-overflow-hidden tw-w-full">
                        <img :src="post.featuredImage" class="tw-object-cover tw-object-center tw-rounded-md" />
                    </div>
                </router-link>
            </div>

            <div class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-w-full tw-items-start tw-py-2">
                <div class="tw-h-full">
                    <category-chip :category="post.category" />

                    <router-link :to="to" class="tw-no-underline tw-block tw-mt-2 tw-heading-5 tw-leading-tight tw-font-bold hover:tw-underline tw-text-black">
                        {{ postGetAttr(post, "title", locale) }}
                    </router-link>
                </div>

                <router-link :to="to" class="tw-text-black tw-font-bold tw-underline tw-inline-block">
                    {{ $t("blog.actions.read") }}
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import CategoryChip from "./category-chip";
import { getRouteLocale } from "@web/constants/language";
import { postGetAttr } from "@web/blog/utils";

export default {
    components: { CategoryChip },
    props: {
        post: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        postGetAttr
    },
    computed: {
        locale() {
            return getRouteLocale();
        },
        to() {
            return {
                name: "blog-detail",
                params: { slug: this.post.slug, locale: this.locale }
            };
        }
    }
};
</script>
